<template>
  <div class="home">
    <div class="container pt-5 pb-5">
      <div class="row justify-content-center pt-5">
        <div class="col-12 d-flex justify-content-center">
          <img width="700px" height="auto" src="@/assets/img/logo-big2.svg">
        </div>
        <div class="col-12 d-flex justify-content-center pt-5 pb-5">
          <h1 class="text-light">{{$t("homeWelcomeText")}} </h1>
        </div>
        <div class="col-12 d-flex justify-content-center pt-5 pb-5">
          <a :href="base_url + 'product/add'" class="btn btn-light round pl-3 pr-3">{{$t("homeFromHereButton")}} <i class="fas fa-arrow-right ml-2"></i></a>
        </div>
      </div>
    </div>
    <!-- <Setting :location="''" /> -->
  </div>
</template>

<script>
import Setting from '@/components/Settings.vue';

export default {
    name : 'Home',
    components:{
      Setting,
    },
    data: function() {
        return{
            base_url: this.$route ? "/" : "",
        }
    },
    mounted() {
      $("body").css("background-color", "#06a");
    },
}
</script>


